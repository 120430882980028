<template lang="pug">

v-container.login-page(fill-height)
  v-layout(align-center justify-center)
    v-card( elevation="2"
    width="100%"
    style="max-width: 450px"
    :loading=loading
    outlined )
      v-card-text.pa-10
        h1.pb-10 Purimax
        form(@submit.prevent="submitForm")
            
          v-text-field(
            outlined
            v-model="login.login"
            label="Login"
            required
          )
          v-text-field(
            outlined
            v-model="login.password"
            label="Senha"
            type="password"
            required
          )
          
          div(v-if="errorMessage")
            v-alert( type="error" dismissible) {{errorMessage}}
          
          div
            v-btn(color="primary" x-large block type="Submit" ref="submitButton") Entrar
</template>

<script>
export default {
    name: 'Login',

    data() {
        return {
            loading: false,
            login: {
                login: '',
                password: ''
            },
            errorMessage: null
        }
    },

    created: function () {
        let message = localStorage.getItem('logoutMessage');

        if (message != '') {
            localStorage.clear()
            this.errorMessage = message
        }
    },

    methods: {
        goLogin: async function () {
            this.errorMessage = ''

            try {
                await this.$store.dispatch('loginUser', this.login)
                this.$router.push({
                    name: 'dashboard'
                })
            } catch (error) {
                if( error?.response?.data?.status === 'fail' ){
                    this.errorMessage = 'Falha na autenticação'
                }else{
                    this.errorMessage = `${error}`
                }                
                //this.$refs.submitButton.removeAttribute('disabled')
                //this.$refs.submitButton.innerHTML = 'Entrar'
            }
        },

        submitForm: function () {
            this.goLogin()
        },

    }
}
</script>
